// @ts-nocheck
import React, { useEffect } from 'react';
import { withLayout } from '@better-bit-fe/base-ui';
import { getTmsMessages } from '@better-bit-fe/lang';
import useFrame from '~/hooks/useFrame';
import { getLang, langList } from '@better-bit-fe/base-utils';
import {
  Footer,
  Partner,
  // Header,
  HomeBanner,
  Advantage,
  Roadmap,
  Security,
  HomeData,
  PerpetualFutures,
  Announcement,
  HoverComp
} from '~/components/HomePage/index';

function Page() {
  useEffect(() => {
    const curLang: any = getLang();
    // 保证其他页面也一致
    localStorage.setItem('LANG_KEY', curLang);
  }, []);
  useFrame();
  return (
    <div className="page-wrapper">
      {/* 导航 */}
      {/* <Header></Header> */}
      {/* banner */}
      <HomeBanner />
      {/* 公告 */}
      <Announcement />
      {/* 数据section */}
      <HomeData />
      {/* 永续 */}
      <PerpetualFutures />
      {/* Most user-friendly eXchange Ever */}
      <Advantage />
      {/* A simpler and most secured way to connect with Mufex */}
      <Security />
      {/* Our roadmap*/}
      <Roadmap />

      {/* 合作伙伴 */}
      <Partner></Partner>
      <Footer></Footer>
      <HoverComp />
    </div>
  );
}

/**
 * https://nextjs.org/docs/basic-features/data-fetching/get-static-props
 *
 * 只在服务端执行，加载的语言内容最终会被打包生成到html中
 *
 * @param ctx
 * @returns
 */
export const getStaticProps = async (ctx) => {
  const { locale, locales, defaultLocale } = ctx;
  const lc = locale || defaultLocale;
  const messages = await getTmsMessages({
    project: 'home-page', //要一一对应
    entry: import.meta.url,
    locale: lc,
    additions: ['title', 'description']
  });

  return {
    props: {
      locale: lc,
      locales,
      messages,
      title: messages.title,
      description: messages.description,
      ogImage: 'https://www.mufex.finance/static/image/ogImage.jpeg'
    }
  };
};

export default withLayout(Page);
